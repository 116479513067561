.u-tooltip-element {
    cursor: help;
    position: relative;
}

.u-tooltip-element:before,
.u-tooltip-element:after {
    display: none;
    position: absolute;
    opacity: 0;
    -webkit-transition-property: opacity, top;
    transition-property: opacity, top;
    -webkit-transition-duration: .1s, .2s;
    transition-duration: .1s, .2s;
    -webkit-transition-timing-function: ease-in, ease-out;
    transition-timing-function: ease-in, ease-out;
    z-index: 1;
}

.u-tooltip-element:after {
    content: attr(data-tooltip);
    font-family: "Open Sans", sans-serif;
    color: #fff;
    background-color: #212735;
    border-radius: 4px;
    text-align: center;
    padding: .3em .75em;
    width: 200px;
    max-width: 350px;
    top: 50%;
    left: 120%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.u-tooltip-element:before {
    content: "";
    display: none;
    position: absolute;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-width: 7.5px;
    border-color: transparent #212735 transparent transparent;
    border-style: solid;
}

.u-tooltip-element.right:before {
    border-color: transparent transparent transparent #212735;
    left: -50%;
}

.u-tooltip-element:focus:after,
.u-tooltip-element:hover:after {
    left: 100%;
    left: calc(100% + 15px);
}

.u-tooltip-element.right:after {
    left: -209px !important;
}

.u-tooltip-element:focus:before,
.u-tooltip-element:hover:before,
.u-tooltip-element:focus:after,
.u-tooltip-element:hover:after {
    display: block;
    opacity: 1;
}
.required_field_warning:after {
    content: ' *';
    color: #FF0000;
    font-weight: 400;
}
