.empty-search__title {
    font-family: Open Sans;
    font-size: 24px;
    margin: 50px 0 35px;
    font-weight: 600;
    color: #444;
}

.empty-search__list {
    margin: 0 auto 35px;
    border-left: 1px solid #888;
    padding: 2px 0 2px 20px;
}

.empty-search__list li {
    color: #444;
    font-size: 13px;
    padding: 2px;
    font-family: Open Sans;
}

.empty-search__btn {
    margin: 0 auto 35px;
    border: 1px solid #888;
    font-family: Open Sans;
    padding: 16px 22px;
    border-radius: 4px;
    font-weight: 700;
    color: #444;
    line-height: 1;
}

.empty-search__btn i {
    vertical-align: middle;
    display: inline-block;
    font-size: 7px;
    padding-left: 15px;
}

@media (max-width: 500px) {
    .empty-search__title {
        font-size: 18px;
        margin: 20px 0 25px;
    }
    .empty-search__list li {
        font-size: 12px;
    }
    .empty-search__list {
        width: 70%;
        padding-left: 15px;
        margin-bottom: 30px;
    }
    .empty-search__btn {
        margin-bottom: 20px;
    }
}
