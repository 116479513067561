.multigrid-content {
    position: relative;
}

.multigrid.multigrid-fixed-column {
    overflow: hidden;
    width: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    border-right: 1px solid #DDD;
    width: 110px;
}

.multigrid.multigrid-fixed-column thead {
    width: 100%;
    display: table;
}

.multigrid {
    display: inline-block;
    border-collapse: collapse;
    margin: 20px 0;
    overflow-x: scroll;
    padding-bottom: 10px;
}

.multigrid tr {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.multigrid tr td:first-child, .multigrid tr th:first-child {
    border-left: 0;
}

.multigrid tr:first-child td {
    border-top: 0;
}

.multigrid td, .multigrid th {
    border-bottom: 1px solid #DDD;
    border-right: 1px solid #DDD;
}

.multigrid tr td:last-child, .multigrid tr th:last-child {
    border-right: 0;
}

.multigrid tr:last-child td {
    border-bottom: 0;
}

.multigrid td {
    width: 110px;
    height: 40px;
    text-align: center;
    font-size: 13px;
    color: #444;
    font-weight: 700;
    word-wrap: break-word;
}

.multigrid td input {
    width: 45px;
    text-align: center;
}