.u-unstyled-list {
    list-style-type: none;
}

.u-vertical-separator:not(:last-child) {
    border-bottom: 1px solid;
}

.borda{
    position: relative;
    border-right: 1px solid #9e9e9e;
    border-left: 1px solid #9e9e9e;
}