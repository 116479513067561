.columns-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
}

.columns-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
}
