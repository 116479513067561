/*
 * Definição de estilos para o diálogo de notificação ao usuário sobre o uso de cookies pelo site.
 */

/* Cria um container transparente sobre a janela do sistema para receber e centralizar o diálogo sobre os cookies. */
.cookies-container{
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    display: flex;
    padding: 30px;
    pointer-events: none;
}

/* Diálogo sobre os cookies em si, área opaca. */
.cookies-dialog{
    display: flex;
    align-items: center;
    width: 80%;
    padding: 15px;
    border-radius: 5px;
    background-color:black;
    color:white;
    flex-grow: 1;
    pointer-events: auto;
}

/* Região onde é apresentada a mensagem sobre o uso dos cookies */
.cookies-message-section{
    flex-grow: 1;
}

/* Região onde é apresentado o botão de prosseguir */
.cookies-button-section{
    margin-left: 15px;
}

/* Botão de aceitar os cookies */
.cookies-accept-btn{
    /* a ser usado nos temas */
}

/* Link para a política de privacidade do site */
.cookies-policy-link{
    font-weight: bold;
}

/* Quando a tela fica pequena, joga o botão de confirmação para baixo do texto para melhorar visual. */
@media only screen and (max-width: 600px){
    .cookies-dialog{
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .cookies-button-section{
        margin-top: 10px;
        margin-left: 0px;
    }
}