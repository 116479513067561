.icon-calendar-restriction {
    padding: 8px;
    background: #0d292f;
    color: #FFF;
    font-size: 13px;
    margin-left: -30px;
    display: inline-block;
}

.icon-calendar-restriction:hover {
    cursor: pointer
}

input#scheduled_delivery {
    padding: 3px 10px 4px;
    font-family: Open Sans, sans-serif !important;
    border: 1px solid #0d292f;
}

input#scheduled_delivery:hover {
    cursor: pointer;
}

.pika-title {
    border-bottom: 1px solid #0d292f;
}

.pika-prev:hover, .pika-next:hover {
    opacity: 1;
    color: #0d292f
}

.pika-table thead {
    border-bottom: 1px solid #0d292f;
}

.pika-table th {
    padding: 5px 0 !important;
}

.pika-row td {
  border-bottom: 1px solid #DDD;
}

.pika-row:last-child td {
  border-bottom: 0;
}

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
    color: #fff;
    background: #777 !important;
    box-shadow: none;
    border-radius: 0 !important;
}

.is-selected .pika-button,
.has-event .pika-button {
    background: #0d292f !important;
    border-radius: 0 !important;
    box-shadow: inset 0 1px 3px #0d292f !important;
}

.is-today .pika-button {
    color: #777 !important;
    font-weight: bold;
    border: 1ps solid #0d292f !important;
}

.pika-single.is-bound {
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .7) !important;
}

.pika-button {
    text-align: center;
}
