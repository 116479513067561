.u-blk {
    display: block;
}

.u-center-blk {
    margin-left: auto;
    margin-right: auto;
}

.u-center-horztl {
    margin: 0 auto;
}

.u-in-blk {
    display: inline-block;
}

.mid,
.u-in-blk.mid {
    vertical-align: middle;
}

.top,
.u-in-blk.top {
    vertical-align: top;
}

.bottom,
.u-in-blk.bottom {
    vertical-align: bottom;
}

.u-justify-items {
    text-align: justify;
    line-height: 0;
}

.u-justify-items:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0;
}

.u-table {
    display: table;
}

.u-table-item {
    display: table-cell;
}

.image-disabled {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}