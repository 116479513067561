/*
 * Estilos para a o painel de cálculo de frete na página de detalhes do produto
 * psp = Product Shipping Panel
 */

.product-shipping-panel{
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 300px;
}

#psp-error-msg-panel{
    display: none; /* Exibido via Javascrip */
}

#shipping-options-list{
    display: none; /* Exibido via Javascrip */
}

.psp-title{
    font-weight: bold;
}

.psp-description{
    margin-top: 3px;
}

.psp-form-panel{
    margin-top: 3px;
}

.psp-form{
    margin-top: 5px;
}

.form-control.psp-form-input{
    max-width: 110px;
    webkit-box-shadow: none;
    box-shadow: none;
    display: inline-block;
}

.psp-submit-btn,
.psp-submit-btn:hover,
.psp-submit-btn:focus,
.psp-submit-btn:active{
    background-color: #08b2d5;
    color: white;
    border: none;
    border-radius: 0;
    vertical-align: bottom;
}

.psp-bottom-panel{
    margin-top: 5px;
}

.psp-correios-link,
.psp-correios-link:hover,
.psp-correios-link:visited,
.psp-correios-link:active{
    color: #08b2d5;
    text-decoration: underline;
}

.psp-all-options-link,
.psp-all-options-link:hover,
.psp-all-options-link:visited,
.psp-all-options-link:active{
    color: #08b2d5;
    text-decoration: underline;
}

.psp-change-zipcode-link,
.psp-change-zipcode-link:hover,
.psp-change-zipcode-link:visited,
.psp-change-zipcode-link:active{
    color: #08b2d5;
    text-decoration: underline;
    cursor: pointer;
}

.psp-options-table{
    width: 100%;
    margin-top: 10px;
}

.psp-option-row{
    background-color: #eeeeee;
    border-bottom: solid 10px white;
}

.psp-option-cell{
    padding: 10px;
}

.psp-option-name{
    text-transform: uppercase;
    font-weight: bold;
}

.psp-option-details{
}

.psp-options-header{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.psp-selected-zipcode{
}

.psp-change-zipcode-box{
}

.psp-options-footer{
    display: none; /* Exibido via Javascrip */
    width: 100%;
    text-align: center;
}

/*
 * Definição de estilos para o diálogo com o conjunto completo de opções de frete
 * psd = Product Shipping Dialog
 */

.psd-options-table{
    width: 100%;
    margin: 10px;
}

.psd-options-table thead tr{
    border-bottom: solid 1px #ddd;
}

.psd-option-row{
    border-bottom: solid 1px #ddd;
}

.psd-option-row:last-child{
    border-bottom: none;
}

.psd-option-name-header-cell{
    font-weight: bold;
    padding:10px;
    text-align: center;
    border-bottom: solid 1px #ddd;
}

.psd-option-deadline-header-cell{
    font-weight: bold;
    padding:10px;
    text-align: center;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
}

.psd-option-price-header-cell{
    font-weight: bold;
    padding:10px;
    text-align: center;
}

.psd-option-name-cell{
    font-weight: bold;
    padding:10px;
    text-align: left;
}

.psd-option-deadline-cell{
    padding:10px;
    text-align: center;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
}

.psd-option-price-cell{
    font-weight: bold;
    padding:10px;
    text-align: center;
    white-space: nowrap;
}
