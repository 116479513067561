.fx {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    flex: 1 1 auto;
}
.fx-wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.fx-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.fx-rows {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
}

.fx-nowrap {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.fx-columns {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
}

.fx-space-justify {
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    line-height: 0;
}
.fx-space-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
    line-height: 0;
}
.fx-space-justify:after {
    content: "";
    height: 0;
    display: inline-block;
    width: 100%;
}

.fx-space-distribute {
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
}

.fx-self-center {
    -ms-align-self: center;
    align-self: center;
}
.fx-vert-center {
    -webkit-align-items: center;
    align-items: center;
}
.fx-horiz-center {
    -webkit-justify-content: center;
    justify-content: center;
}
.fx-grow {
   flex-grow: 1;
}
.fx-main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -ms-flexbox;
    display: flex !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    -webkit-align-items: center;
    align-items: center;
}
.fx-init {
  justify-content: flex-start;
  align-items: center;
}
.fx-main-wrap {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: wrap;
}
.fx-main-init {
  display: flex;
  flex-wrap: wrap;
  justify-content: initial;
  width: 22%;
}
.fx-centerid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fx-main-init-nowrap {
  display: flex;
  justify-content: initial;
  align-items: center;
}
.fx-items-start{
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.fx-rows-full {
    width: 100%;
}