.u-color-inherit {
	color: inherit;
}

.u-color-inherit:hover {
	color: inherit;
}

.u-color-inherit:active {
	color: inherit;
}

.u-color-inherit:visited {
	color: inherit;
}