.u-no-float {
    float: none !important;
}
.u-center {
	margin: 0 auto;
}
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
.fixed {
	position:fixed;
}
.position-static {
    position: static !important;
}
.justify-children {
	text-align: justify;
}
.justify-children::after {
	content: " ";
	display: inline-block;
	width: 100%;
}
.u-fit{
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}
.col-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
