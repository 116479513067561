aside {
    float: none !important;
}

.filter {
    font-family: 'Open Sans', sans-serif;
    background-color: #fff;
    padding: 7px 0;
    margin-bottom: 5px;
}

.filter--no-background {
    background-color: initial;
}

.filter__clear-all {
    font-size: 14px;
    padding-left: 19px;
    margin-top: 20px;
    display: block;
    color: #888;
    padding: 6px 12px;
    background: #EEE;
    border: 1px solid #BBB;
    position: relative;
}

.filter__clear-all:hover,
.filter__clear-all:hover::after {
    color: #666 !important;
    text-decoration: none;
}

.filter__clear-all::after {
    content: "\f00d";
    font-family: FontAwesome;
    position: absolute;
    right: 8px;
    border-left: 1px solid #BBB;
    padding-left: 8px;
    color: #999;
}

.filter-option {
    display: block;
    max-height: 40px;
    overflow: hidden;
}

.filter-option + .filter-option {
    border-top: 1px solid #e8e8e8;
}

.filter-option--active {
    max-height: 1500px;
    box-shadow: inset 0px -1px 1px #efefef;
}

.filter-option__name {
    font-size: 18px;
    font-weight: 500;
    color: #292f36;
    display: block;
    padding: 0 20px;
    line-height: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #CCC;
}

.filter-option--active .filter-option__name {
    box-shadow: 0px 1px 2px #efefef;
}

.filter-option--has-dropdown::after {
    content: '+';
    display: block;
    padding: 0 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    color: #666;
    font-size: 24px;
}

.filter-option--active .filter-option--has-dropdown::after {
    content: '-';
}

.filter-values {
    list-style-type: none;
    margin: 0 16px 0 20px;
    max-height: 300px;
    overflow-x: auto;
    border-style: solid;
    border-width: 10px 0;
    border-color: transparent;
}

.filter-value {
    position: relative;
}

.filter-size li {
    display: inline-block;
    margin: 3px 1px;
}

.filter-size-value_item {
    border: 1px solid #292f36;
    padding: 3px 5px;
    display: inline-block;
}

.filter-size-value_item:hover,
.filter-size-value--selected {
    text-decoration: none;
    background: #292f36;
    color: #FFF !important;
}

.filter-value + .filter-value {
    margin-top: 3px;
}

.filter-value__link {
    font-size: 14px;
    font-weight: 400;
    color: #292f36;
}

.filter-value__link:hover,
.filter-value__link:focus {
    color: #292f36;
    text-decoration: none;
}

.filter-value__link::before {
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid #292f36;
    padding: 2px;
    display: inline-block;
    vertical-align: middle;
    background-clip: content-box;
    position: relative;
    top: -2px;
    border-radius: 3px;
    margin-right: 5px;
}

.filter-value__link:hover::before,
.filter-value--selected .filter-value__link:hover::before {
    content: '\f046';
    font-family: FontAwesome !important;
    border: 0;
    font-size: 20px;
    margin-left: -1px;
    margin-right: 6px;
    margin-top: -14px;
}

.filter-value--selected .filter-value__link::before {
    content: '\f046';
    font-family: FontAwesome !important;
    border: 0;
    font-size: 20px;
    margin-left: -1px;
    margin-right: 6px;
    margin-top: -14px;
}

.sort-by {
    margin-left: auto;
    display: table;
    margin-bottom: 1em;
}

.sort-by__label {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
}

.sort-by__select {
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
.sort-by__select select {
    background-color: transparent;
    -moz-appearance: none;
    -webkit-appearance: textfield;
    border: none;
    display: block;
    cursor: pointer;
    min-width: 184px;
    height: 3em;
    padding: 0 1.6em;
}

.sort-by__select select::-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.datepicker {
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

@media only screen and (max-width: 500px) {

  .filter-otpion-mobile {
    display: block;
    max-height: 40px;
    overflow: hidden;
  }
  .filter-option-mobile-active {
      max-height: 1500px;
      box-shadow: inset 0px -1px 1px #efefef;
  }

}
@media only screen and (max-width: 768px) {
  .filter-otpion-mobile {
    display: block;
    max-height: 40px;
    overflow: hidden;
  }
  .filter-option-mobile-active {
      max-height: 1500px;
      box-shadow: inset 0px -1px 1px #efefef;
  }
}


.filter-search {
    width: 94%;
}

.header-search-button {
    z-index: 2 !important;
}
