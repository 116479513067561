.u-text-default {
    font-family: 'Open Sans';
}

.u-text-light {
    font-weight: 300;
}

.u-text-bold {
    font-weight: 700;
}

.u-text-upper {
    text-transform: uppercase;
}

.u-text-cap {
    text-transform: capitalize;
}

.u-text-low {
  text-transform: lowercase;
}
.text-danger {
    color: #a94442 !important;
}
#message-newsletter {
    color: #077552;
    font-weight: 600;
    text-align: center;
}
.u-text-italic {
    font-style: italic;
}

.u-text-colored {
    color: #bfb192;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

.u-text-center {
    text-align: center;
}

.u-text-justify {
    text-align: justify;
}

.u-text-justify.u-last-line-left {
    -ms-text-align-last: right;
    text-align-last: left;
}

.u-text-justify.u-last-line-center {
    -ms-text-align-last: center;;
    text-align-last: center;;
}

.u-font-size-1 {
    font-size: 1em;
}

.u-font-size-1-5 {
    font-size: 1.5em;
}

.u-font-size-2 {
    font-size: 2em;
}

.u-reset-lh {
    line-height: initial;
}

.u-text-replace {
    display: block;
    text-indent: -9999px;
    z-index: -9999;
}
