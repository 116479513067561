html, body {
    height: 100%;
}
ul {
    padding:0;
    list-style:none;
}
a:focus,
select:focus,
input:focus,
option:focus,
li:focus {
    outline: none;
}
img {
    max-width:100%;
    height:auto
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 0 15px;
    font-family: 'Open Sans', sans-serif;
    color: #444444;
}
a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
}
a:hover {
    color: inherit !important;
}