.u-auto-width {
    width: auto !important;
}

.u-clearfix:before,
.u-clearfix:after {
    content: "";
    display: table;
}

.u-clearfix,
.u-clearfix:after {
    clear: both;
}

.u-fill-space {
    width: 100%;
}
body.full-box-activated {
    overflow:hidden;
}
.full-box {
    position:fixed;
    width:100%;
    height:100%;
    background:white;
    z-index:2;
    top:0;
    left:0;
    display:none;
    overflow-x: hidden;
    overflow-y: scroll;
}
@media (min-width: 1024px) {
    .u-col-20 {
        width: 20%;
    }
}

@media (min-width: 768px) {
    .u-max-col-25 {
        max-width: 25%;
    }
}
