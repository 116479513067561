.service-button {
    width: 100%;
}
.subscriptions-left-panel {
    padding: 0px;
}

.cel-subscriptions-list {
    padding: 30px 15px 30px 15px;
}

.goto-description-link, .hr-bold {
    color: #4eaf79;
}

.disabled-color {
    color: #C9C9C9 !important;
}

.subscription-details-padding-from-title {
    height: 50px;
}

.empty-space {
    height: 80px;
}

.next-installment-details, .next-installment-list {
    font-size: 0.9em !important;
}

.next-installment-details {
    color: #4C4D4F;
    font-weight: 600;
}

.next-installment-list {
    padding-top: 5px !important;
    color: #9F9F9F;
}

.subscription-status-list {
    margin-top: 17px !important;
}

.service-name {
    padding-top: 10px;
    font-size: 18px;
    margin-left: -15px;
}

.service-price {
    margin-right: -15px;
}

.service-canceled-text{
    font-weight: 600;
}

.service-canceled-text-top{
    font-size: 18px;
}

@media (max-width: 767px) /* xs */ {
    .service-description-box, .serivce-canceled-box{
        margin-right: -20px;
        margin-left: -20px;
    }
}
@media (max-width: 991px) /* sm */ {
    .service-button {
        padding: .4em 0em !important;
    }
    .service-description-box{
        background-color: #eee;
        margin-top: 20px;
        padding-top: 20px;
    }
    .service-canceled-box{
        margin-top: 20px;
        padding-top: 20px;

    }
}
@media (max-width: 1199px) /* md */ {}
@media (min-width: 1200px) /* lg */ {}

@media (min-width: 992px) /* xs */ {
    .subscriptions-external-box {
        display: flex;
    }
    .subscriptions-left-panel {
        padding: 0px 20px;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
        border-color: #ddd;
        min-height: 200px; max-height: 200px;
    }
    .box-full-height {
        height: 165px;
    }
    .service-name-box {
        border-bottom: 2px solid rgb(238, 238, 238);
        padding-bottom: 10px;
    }
}

.hr-bold {
    border-top: 5px solid;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.btn-arrow-right {
    margin-right: 10px;
    margin-top: 5px;
}

/* CANCEL MODAL */

.close{
  font-size: 40px;
}

.modal-text-top{
    font-size: 18px;
    font-weight: 600;
}

.green-link{
    font-weight: 600;
    color: #5DC92B;
    text-decoration: underline;
}

.green-link-16{
    font-size: 16px;
    font-weight: 600;
    color: #5DC92B;
}

.cancel-button{
    background-color: #fff;
    color: #5DC92B;
    border: 1px solid #5DC92B;
    border-radius: 6px;
    outline: none;
    padding: 10px;
}

.cancel-button:focus {
    outline:none !important;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-body{
    padding: 20px !important;
}

.margin-top-15{
    margin-top: 15;
}

.margin-top-25{
    margin-top: 25px;
}

.margin-top-40{
    margin-top: 40px;
}

@media screen and (min-width: 768px) { 
    
    .modal-dialog {
        min-height: calc(100% - (1.75rem * 2));
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.75rem auto;
    }

    .modal-dialog .modal-content {
        width: 100%;
    }
}