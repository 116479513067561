/* iphone 6 */
@media only screen and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) {

    .toggle-categories-menu {
        width: 40px !important;
        height: 40px !important;
    }

    .toggle-categories-menu::before {
        position: relative;
        right: 8px;
    }
    .toggle-categories::before {
        position: relative;
        right: 8px;
        top:1px;
    }
    .iphone-rule {
        display:inline-block ;
        float:none !important;
        flex-wrap:nowrap !important;
    }
    .iphone-rule .col-xs-6 {
        width:49.9%!important;
        margin:0!important;
        padding:0!important;
        height: 370px;
    }
}

/* iphone 6+ */
@media screen and (min-device-width : 414px) and (-webkit-device-pixel-ratio: 3) {

    .toggle-categories-menu {
        width: 40px !important;
        height: 40px !important;
    }

    .toggle-categories-menu::before {
        position: relative;
        right: 8px;
    }
    .toggle-categories::before {
        position: relative;
        right: 8px;
        top:1px;
    }
    .iphone-rule {
        display:inline-block;
        float:none !important;
        flex-wrap:nowrap !important;
    }
     .iphone-rule .col-xs-6 {
        width:49.9%!important;
        margin:0!important;
        padding:0!important;
        height: 370px;
    }
}
@media screen and (min-device-width : 1024px) {
    /* Safari 7.1+ */
    _::-webkit-full-page-media, _:future, :root .iphone-rule .col-md-20 {

        width: 19.9% !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 400px;
    }

    _::-webkit-full-page-media, _:future, :root .iphone-rule .col-md-3 {
        width: 24% !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 400px;
    }
}
