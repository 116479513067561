.variant-select-wrapper {
    position: relative;
}

.variant-select-label,
.variants-legend {
    color: #444;
}

.variants-legend {
    margin-bottom: 7px;
}

.variant-select-wrapper + .variant-select-wrapper {
    margin-top: 18px;
}

.variant-select-box {
    border: none;
    background-color: #f0f0f0;
    border-radius: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.5em;
    min-width: 125px;
}

.variant-select {
    padding: 0 .75em;
    display: block;
    width: 100%;
    float: none;
    clear: both;
    color: #535353;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    position: relative;
}

.variant-select:focus {
    outline: none;
}

.variant-select:active > .select-arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.select-arrow {
    position: absolute;
    top: 50%;
    right: 10%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #535353;
    font-size: 2em;
    transition: all .3s ease;
}

.variant-select-option {
    padding: .75em;
}

.variant-select,
.variant-select-option {
    font-weight: bold;
}
