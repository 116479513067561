/**
 * Folha de estilos para o componente VueJS de dados de pagamento Pix. Ver:
 *   - resources/assets/common/js/checkout/pix/PixPanelVue.js
 *   - resources/views/common/components/pix-panel/pix-panel.blade.php
 */
 
 .pixp-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pixp-panel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pixp-expiration {
    padding: 20px;
}

.pixp-qrcode-panel {
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
}

.pixp-copy-panel {
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
}

.pixp-qrcode-image-panel {
    margin: 0 20px 20px 20px;
}

.pixp-steps-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pixp-step {
    display:flex;
    margin: 8px 0 8px 0;
}

.pixp-step-image-panel {
    min-width: 35px;
    min-height: 35px;
}

.pixp-step-description {
    display: flex;
    align-items: center;
    margin-left: 10px;
    text-align: left;
}

.pixp-copy-detail-panel {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    margin: 10px 20px 0 20px;
    align-items: center;
    min-height: 120px;
}

.pixp-code-input {
    max-width: 150px;
}

.pixp-copy-button {
    text-transform: uppercase;
    padding: 5px;
    margin: 15px 0 15px 0;
}

.pixp-copy-msg {
    color: #4eaf79;
    font-weight: bold;
    visibility: hidden;
}

.pixp-switch-mode-link {
    cursor: pointer;
    font-weight: bold !important;
}

.pixp-hr {
    width:100%
}

.pixp-summary-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

[v-cloak] {
  display: none;
}

@media (min-width: 991px){
    .pixp-qrcode-rel-pos-text:after {
        content: '2. Escolha a opção de pagar com QR code e escaneie o código ao lado';
    }
    .pixp-copycode-rel-pos-text:after {
        content: '1. Copie o código Pix disponibilizado ao lado';
    }
}

@media (max-width: 992px){
    .pixp-qrcode-rel-pos-text:after {
        content: '2. Escolha a opção de pagar com QR code e escaneie o código acima';
    }
    .pixp-copycode-rel-pos-text:after {
        content: '1. Copie o código Pix disponibilizado acima';
    }
}