/*
    O objetivo dessa classe consiste em esconder elementos
    com a classe mob em dispositivos desktop e mostra-los
    em resoluções para mobile, como ja existem temas que abrangem essa regra,
    manter esse arquivo até que o identificador de dispositivos esteja totalmente em vigor.
*/

.overflow-content {
    overflow-x: hidden;
    width: 100%;
}
