.order-details {
    display: block;
    width: 100%;
    font-family: 'Open Sans', Helvetica, sans-serif;
}

.order-details__heading {
    position: relative;
}

.order-print-payment {
    padding: 15px 0;
    margin: 30px auto 20px;
}

.order-print-payment p {
    margin: 0 2em 0 0;
}

.order-generated-failed-payment {
    background-color: #f5f5f5;
    padding: 20px;
    transition: box-shadow .1s ease;
    box-shadow: 0 0 8px rgba(0,0,0,.25);
}

.order-generated-failed-payment p span {
    color: #B33A3A;
}

.order-details__return {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    display: inline-block;
    padding: .25em .5em;
}

.order-details tr th {
    font-weight: 500;
    text-align: right;
    width: 192px;
}

.order-details tr td {
    font-weight: 300;
    padding-left: 1em;
    max-width: 430px;
    word-wrap: break-word;
}

.order-products {
    width: 100%;
    background-color: #fff;
    border: 1px solid #c7c7c7;
    border-collapse: separate;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
}

.order-products tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.order-products thead th {
    font-weight: 600;
    padding: 1.5em 1em;
    border-bottom: 2px solid #ddd;
}

.order-products thead th,
.order-products tbody td {
    text-align: center;
}

.order-products tbody tr td img {
    max-width: 50px;
    max-height: 50px;
}

.order-products thead th:first-child,
.order-products thead th:nth-child(2),
.order-products tbody td:first-child,
.order-products tbody td:nth-child(2) {
    text-align: left;
}

.order-products tbody tr th,
.order-products tbody tr td {
    padding: .75em 1em;
}

.order-products tbody td {
    font-weight: 300;
}

.order-products tfoot td {
    border-top: 2px solid #ddd;
    padding: 0.5em 1em;
}

.order-products tfoot td div {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em;
}

.order-message {
    padding: 0.5em;
    background: #eaeaea;
    border-radius: 4px;
}

.order-message:not(:only-child):not(:last-child) {
    border-bottom: 1px solid #4eaf79;
    padding-bottom: 1.5em;
}

.order-message + .order-message {
    margin-top: 1.5em;
}

.order-message__header {
    margin-bottom: 1em;
}

.order-message__body {
    font-weight: 300;
    font-size: 13px;
}

.order-message__text {
    text-align: justify;
    -ms-text-align-last: left;
    text-align-last: left;
}

.order-message__reply {
    background-color: #f5f5f5;
    padding: .5em .5em .5em 3em;
}

.order-message__textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding: 1em;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
}

.order__success-heading { }

.order__success-heading:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00b087 url('../img/icon/common/order-generated-icon.png') center no-repeat;
}

.order__error-heading {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: red;
}

.order__error-icon {
    color: red;
    transform: rotate(45deg);
}

.order-total {
    font-size: 20px;
    margin-bottom: 0;
}

.order__data-panel {
    min-height: 90px;
}

.order__send-message {
    margin-right: 1em;
    margin-bottom: 1em;
}

.order__footer {
    margin-bottom: 50px;
}

#boletoNumberCopyButton {
    margin-left: -30px;
    margin-right: 13px;
    background: #ffffff;
    position: relative;
}

#boletoNumberCopyButton i {
    margin-left: 5px;
    width: 20px;
}

.credit-card-icon {
    font-size: 1.5em;
    margin-bottom: 10px;
}
.order__pix-spinner {
    color: #00b087;
    margin-bottom: 15px;
}
.order__creditCardBoleto-header, .order__sucess-header {
    color: #00b087;
}

.order__pix-header {
    margin-bottom: 60px;
    font-size: 140%;
}

.order__pix-header h3 {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

.order__pix-header p {
    font-size: 14px;
}

@media screen and (max-width: 1024px){
    .order-print-payment p {
        margin: 0;
        width: 100%;

    }
    .ip__button--order-generated-header {
        white-space: nowrap;
    }
    .order-details tr th {
        width: 25%;
    }
    table.order-details{
        display: table;
        table-layout: fixed;
    }
    table.order-products{
        display: block;
        overflow: scroll;
    }
    .order__footer a{
        width: 50%;
        margin: 0 auto;
    }
}

.payment-method-baloon {
    background-color: #000000;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 4px 12px;
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
    font-size: 10px;
    display: block;
    max-width: 75px;
    text-align: center;
}

@media screen and (max-width: 767px){
    .order__footer a{
        width: 100%;
    }
}