.fa-stack-0-5x {
    font-size: 0.5em !important;
    line-height: inherit !important;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}
.fa-stack-custom {
    width: 1em !important;
    height: 1em !important;
    line-height: 1em !important;
}