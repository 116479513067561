@font-face {
    font-family: "FontAwesome";
    src: url("../fonts/common/fontawesome-webfont/fontawesome-webfont.eot");
    src: url("../fonts/common/fontawesome-webfont/fontawesome-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/common/fontawesome-webfont/fontawesome-webfont.woff") format("woff"),
    url("../fonts/common/fontawesome-webfont/fontawesome-webfont.woff2") format("woff2"),
    url("../fonts/common/fontawesome-webfont/fontawesome-webfont.ttf") format("truetype"),
    url("../fonts/common/fontawesome-webfont/fontawesome-webfont.svg") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSans-Regular.eot");
    src: url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype"),
    url("../fonts/OpenSans/OpenSans-Regular.woff") format('woff');
    font-display: swap;
}

@font-face {
    font-family: "Futura";
    src: url("../fonts/Futura/futura-regular.ttf") format("truetype");
}

@font-face {
    font-family: "Bebas Neue";
    src: url("../fonts/bebas_neue/BebasNeue Regular.otf") format("opentype");
}

@font-face {
    font-family: "RobotoMono";
    src: url("../fonts/RobotoMono/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Anton";
    src: url("../fonts/Anton/Anton.ttf") format("truetype");
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('../fonts/gotham/Gotham-Medium.eot');
    src: url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
        url('../fonts/gotham/Gotham-Medium.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Book.eot');
    src: url('../fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Book.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Book.woff') format('woff'),
        url('../fonts/gotham/Gotham-Book.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Book.svg#Gotham-Book') format('svg');
}

@font-face {
    font-family: "Raleway SemiBold";
    src: url("../fonts/raleway/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway Bold";
    src: url("../fonts/raleway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway ExtraBold";
    src: url("../fonts/raleway/Raleway-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway";
    src: url('../fonts/raleway/Raleway-Regular.eot');
    src: url('../fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/raleway/Raleway-Regular.woff2') format('woff2'),
        url('../fonts/raleway/Raleway-Regular.woff') format('woff'),
        url('../fonts/raleway/Raleway-Regular.ttf') format('truetype'),
        url('../fonts/raleway/Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-SemiBold.eot');
    src: url('../fonts/raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/raleway/Raleway-SemiBold.woff2') format('woff2'),
        url('../fonts/raleway/Raleway-SemiBold.woff') format('woff'),
        url('../fonts/raleway/Raleway-SemiBold.ttf') format('truetype'),
        url('../fonts/raleway/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Bold.eot');
    src: url('../fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/raleway/Raleway-Bold.woff2') format('woff2'),
        url('../fonts/raleway/Raleway-Bold.woff') format('woff'),
        url('../fonts/raleway/Raleway-Bold.ttf') format('truetype'),
        url('../fonts/raleway/Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-ExtraBold.eot');
    src: url('../fonts/raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/raleway/Raleway-ExtraBold.woff2') format('woff2'),
        url('../fonts/raleway/Raleway-ExtraBold.woff') format('woff'),
        url('../fonts/raleway/Raleway-ExtraBold.ttf') format('truetype'),
        url('../fonts/raleway/Raleway-ExtraBold.svg#Raleway-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "FiraSans";
    src: url("../fonts/Fira_Sans/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "FiraSans";
    src: url("../fonts/Fira_Sans/FiraSans-Light.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "FiraSans";
    src: url("../fonts/Fira_Sans/FiraSans-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "FiraSans";
    src: url("../fonts/Fira_Sans/FiraSans-ExtraBold.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "FiraMono";
    src: url("../fonts/Fira_Mono/FiraMono-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SquadaOne";
    src: url("../fonts/Squada_One/SquadaOne-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Caveat Brush';
    font-style: normal;
    font-weight: 400;
    src: local('Caveat Brush'), local('CaveatBrush-Regular'),
         url('../fonts/caveat-brush/caveat-brush.ttf') format('truetype'),
         url('../fonts/caveat-brush/caveat-brush.woff') format('woff'),
         url('../fonts/caveat-brush/caveat-brush.woff2') format('woff2');
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Light.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Light.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype'),
        url('../fonts/Ubuntu/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Medium.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype'),
        url('../fonts/Ubuntu/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Bold.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype'),
        url('../fonts/Ubuntu/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-ExtraLight.eot');
    src: url('../fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Light.eot');
    src: url('../fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Light.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Thin.eot');
    src: url('../fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Black.eot');
    src: url('../fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Black.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot');
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans/NunitoSans-Black.eot');
    src: url('../fonts/NunitoSans/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NunitoSans/NunitoSans-Black.woff2') format('woff2'),
        url('../fonts/NunitoSans/NunitoSans-Black.woff') format('woff'),
        url('../fonts/NunitoSans/NunitoSans-Black.ttf') format('truetype'),
        url('../fonts/NunitoSans/NunitoSans-Black.svg#NunitoSans-Black') format('svg');
    font-weight: 900;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans/NunitoSans-ExtraLight.eot');
    src: url('../fonts/NunitoSans/NunitoSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NunitoSans/NunitoSans-ExtraLight.woff2') format('woff2'),
        url('../fonts/NunitoSans/NunitoSans-ExtraLight.woff') format('woff'),
        url('../fonts/NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype'),
        url('../fonts/NunitoSans/NunitoSans-ExtraLight.svg#NunitoSans-ExtraLight') format('svg');
    font-weight: 200;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans/NunitoSans-SemiBold.eot');
    src: url('../fonts/NunitoSans/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NunitoSans/NunitoSans-SemiBold.woff2') format('woff2'),
        url('../fonts/NunitoSans/NunitoSans-SemiBold.woff') format('woff'),
        url('../fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype'),
        url('../fonts/NunitoSans/NunitoSans-SemiBold.svg#NunitoSans-SemiBold') format('svg');
    font-weight: 600;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans/NunitoSans-ExtraBold.eot');
    src: url('../fonts/NunitoSans/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NunitoSans/NunitoSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/NunitoSans/NunitoSans-ExtraBold.woff') format('woff'),
        url('../fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/NunitoSans/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
    font-weight: 800;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans/NunitoSans-Light.eot');
    src: url('../fonts/NunitoSans/NunitoSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NunitoSans/NunitoSans-Light.woff2') format('woff2'),
        url('../fonts/NunitoSans/NunitoSans-Light.woff') format('woff'),
        url('../fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype'),
        url('../fonts/NunitoSans/NunitoSans-Light.svg#NunitoSans-Light') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.eot');
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.woff2') format('woff2'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.woff') format('woff'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.ttf') format('truetype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Light.svg#FiraSansExtraCondensed-Light') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.eot');
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.woff2') format('woff2'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.woff') format('woff'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.ttf') format('truetype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Regular.svg#FiraSansExtraCondensed-Regular') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.eot');
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.woff2') format('woff2'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.woff') format('woff'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.ttf') format('truetype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-Medium.svg#FiraSansExtraCondensed-Medium') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.eot');
    src: url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.woff2') format('woff2'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.woff') format('woff'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.ttf') format('truetype'),
        url('../fonts/FiraSansExtraCondensed/FiraSansExtraCondensed-SemiBold.svg#FiraSansExtraCondensed-SemiBold') format('svg');
    font-weight: 600;
}

@font-face {
    font-family: 'AllRoundGothicW03';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Medium.svg#AllRoundGothicW03-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AllRoundGothicW01';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Book.svg#AllRoundGothicW01-Book') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AllRoundGothicW01';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Medium.svg#AllRoundGothicW01-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AllRoundGothicW03';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Bold.svg#AllRoundGothicW03-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AllRoundGothicW03';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW03-Book.svg#AllRoundGothicW03-Book') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AllRoundGothicW01';
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.eot');
    src: url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.woff2') format('woff2'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.woff') format('woff'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.ttf') format('truetype'),
        url('../fonts/AllRoundGothicW/AllRoundGothicW01-Bold.svg#AllRoundGothicW01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/QuickSand/Quicksand-Medium.eot');
    src: url('../fonts/QuickSand/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuickSand/Quicksand-Medium.woff2') format('woff2'),
        url('../fonts/QuickSand/Quicksand-Medium.woff') format('woff'),
        url('../fonts/QuickSand/Quicksand-Medium.ttf') format('truetype'),
        url('../fonts/QuickSand/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/QuickSand/Quicksand-Regular.eot');
    src: url('../fonts/QuickSand/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuickSand/Quicksand-Regular.woff2') format('woff2'),
        url('../fonts/QuickSand/Quicksand-Regular.woff') format('woff'),
        url('../fonts/QuickSand/Quicksand-Regular.ttf') format('truetype'),
        url('../fonts/QuickSand/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/QuickSand/Quicksand-Light.eot');
    src: url('../fonts/QuickSand/Quicksand-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuickSand/Quicksand-Light.woff2') format('woff2'),
        url('../fonts/QuickSand/Quicksand-Light.woff') format('woff'),
        url('../fonts/QuickSand/Quicksand-Light.ttf') format('truetype'),
        url('../fonts/QuickSand/Quicksand-Light.svg#Quicksand-Light') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/QuickSand/Quicksand-Bold.eot');
    src: url('../fonts/QuickSand/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuickSand/Quicksand-Bold.woff2') format('woff2'),
        url('../fonts/QuickSand/Quicksand-Bold.woff') format('woff'),
        url('../fonts/QuickSand/Quicksand-Bold.ttf') format('truetype'),
        url('../fonts/QuickSand/Quicksand-Bold.svg#Quicksand-Bold') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: 'WildOnes';
    src: url('../fonts/WildOnes/WildOnes.eot');
    src: url('../fonts/WildOnes/WildOnes.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WildOnes/WildOnes.woff2') format('woff2'),
        url('../fonts/WildOnes/WildOnes.woff') format('woff'),
        url('../fonts/WildOnes/WildOnes.ttf') format('truetype'),
        url('../fonts/WildOnes/WildOnes.svg#WildOnes') format('svg');
}

@font-face {
    font-family: 'Clarendon';
    src: url('../fonts/ClarendonBT/ClarendonBT-Roman.eot');
    src: url('../fonts/ClarendonBT/ClarendonBT-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ClarendonBT/ClarendonBT-Roman.woff2') format('woff2'),
        url('../fonts/ClarendonBT/ClarendonBT-Roman.woff') format('woff'),
        url('../fonts/ClarendonBT/ClarendonBT-Roman.ttf') format('truetype'),
        url('../fonts/ClarendonBT/ClarendonBT-Roman.svg#ClarendonBT-Roman') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Clarendon';
    src: url('../fonts/ClarendonBT/ClarendonBT-Light.eot');
    src: url('../fonts/ClarendonBT/ClarendonBT-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ClarendonBT/ClarendonBT-Light.woff2') format('woff2'),
        url('../fonts/ClarendonBT/ClarendonBT-Light.woff') format('woff'),
        url('../fonts/ClarendonBT/ClarendonBT-Light.ttf') format('truetype'),
        url('../fonts/ClarendonBT/ClarendonBT-Light.svg#ClarendonBT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BloggerSans';
    src: url('../fonts/BloggerSans/BloggerSans.eot');
    src: url('../fonts/BloggerSans/BloggerSans.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BloggerSans/BloggerSans.woff2') format('woff2'),
        url('../fonts/BloggerSans/BloggerSans.woff') format('woff'),
        url('../fonts/BloggerSans/BloggerSans.ttf') format('truetype'),
        url('../fonts/BloggerSans/BloggerSans.svg#BloggerSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BloggerSans';
    src: url('../fonts/BloggerSans/BloggerSans-Light.eot');
    src: url('../fonts/BloggerSans/BloggerSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BloggerSans/BloggerSans-Light.woff2') format('woff2'),
        url('../fonts/BloggerSans/BloggerSans-Light.woff') format('woff'),
        url('../fonts/BloggerSans/BloggerSans-Light.ttf') format('truetype'),
        url('../fonts/BloggerSans/BloggerSans-Light.svg#BloggerSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BloggerSans';
    src: url('../fonts/BloggerSans/BloggerSans-Medium.eot');
    src: url('../fonts/BloggerSans/BloggerSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BloggerSans/BloggerSans-Medium.woff2') format('woff2'),
        url('../fonts/BloggerSans/BloggerSans-Medium.woff') format('woff'),
        url('../fonts/BloggerSans/BloggerSans-Medium.ttf') format('truetype'),
        url('../fonts/BloggerSans/BloggerSans-Medium.svg#BloggerSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BloggerSans';
    src: url('../fonts/BloggerSans/BloggerSans-Bold.eot');
    src: url('../fonts/BloggerSans/BloggerSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BloggerSans/BloggerSans-Bold.woff2') format('woff2'),
        url('../fonts/BloggerSans/BloggerSans-Bold.woff') format('woff'),
        url('../fonts/BloggerSans/BloggerSans-Bold.ttf') format('truetype'),
        url('../fonts/BloggerSans/BloggerSans-Bold.svg#BloggerSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SimplonBP';
    src: url('../fonts/SimplonBP/SimplonBP-Light.eot');
    src: url('../fonts/SimplonBP/SimplonBP-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SimplonBP/SimplonBP-Light.woff2') format('woff2'),
        url('../fonts/SimplonBP/SimplonBP-Light.woff') format('woff'),
        url('../fonts/SimplonBP/SimplonBP-Light.ttf') format('truetype'),
        url('../fonts/SimplonBP/SimplonBP-Light.svg#SimplonBP-Light') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SimplonBP';
    src: url('../fonts/SimplonBP/SimplonBP-Regular.eot');
    src: url('../fonts/SimplonBP/SimplonBP-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SimplonBP/SimplonBP-Regular.woff2') format('woff2'),
        url('../fonts/SimplonBP/SimplonBP-Regular.woff') format('woff'),
        url('../fonts/SimplonBP/SimplonBP-Regular.ttf') format('truetype'),
        url('../fonts/SimplonBP/SimplonBP-Regular.svg#SimplonBP-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SimplonBP';
    src: url('../fonts/SimplonBP/SimplonBP-Bold.eot');
    src: url('../fonts/SimplonBP/SimplonBP-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SimplonBP/SimplonBP-Bold.woff2') format('woff2'),
        url('../fonts/SimplonBP/SimplonBP-Bold.woff') format('woff'),
        url('../fonts/SimplonBP/SimplonBP-Bold.ttf') format('truetype'),
        url('../fonts/SimplonBP/SimplonBP-Bold.svg#SimplonBP-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SimplonBP';
    src: url('../fonts/SimplonBP/SimplonBP-Medium.eot');
    src: url('../fonts/SimplonBP/SimplonBP-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SimplonBP/SimplonBP-Medium.woff2') format('woff2'),
        url('../fonts/SimplonBP/SimplonBP-Medium.woff') format('woff'),
        url('../fonts/SimplonBP/SimplonBP-Medium.ttf') format('truetype'),
        url('../fonts/SimplonBP/SimplonBP-Medium.svg#SimplonBP-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Simplon Oi';
    src: url('../fonts/SimplonOi/SimplonOi-Headline.eot');
    src: url('../fonts/SimplonOi/SimplonOi-Headline.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SimplonOi/SimplonOi-Headline.woff2') format('woff2'),
        url('../fonts/SimplonOi/SimplonOi-Headline.woff') format('woff'),
        url('../fonts/SimplonOi/SimplonOi-Headline.ttf') format('truetype'),
        url('../fonts/SimplonOi/SimplonOi-Headline.svg#SimplonOi-Headline') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AbrilFatface';
    src: url('../fonts/AbrilFatface/AbrilFatface-Regular.eot');
    src: url('../fonts/AbrilFatface/AbrilFatface-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AbrilFatface/AbrilFatface-Regular.woff2') format('woff2'),
        url('../fonts/AbrilFatface/AbrilFatface-Regular.woff') format('woff'),
        url('../fonts/AbrilFatface/AbrilFatface-Regular.ttf') format('truetype'),
        url('../fonts/AbrilFatface/AbrilFatface-Regular.svg#AbrilFatface-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo 2';
    src: url('../fonts//Baloo2/Baloo2-Regular.eot');
    src: url('../fonts//Baloo2/Baloo2-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts//Baloo2/Baloo2-Regular.woff2') format('woff2'),
        url('../fonts//Baloo2/Baloo2-Regular.woff') format('woff'),
        url('../fonts//Baloo2/Baloo2-Regular.ttf') format('truetype'),
        url('../fonts//Baloo2/Baloo2-Regular.svg#Baloo2-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo 2';
    src: url('../fonts/Baloo2/Baloo2-Medium.eot');
    src: url('../fonts/Baloo2/Baloo2-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baloo2/Baloo2-Medium.woff2') format('woff2'),
        url('../fonts/Baloo2/Baloo2-Medium.woff') format('woff'),
        url('../fonts/Baloo2/Baloo2-Medium.ttf') format('truetype'),
        url('../fonts/Baloo2/Baloo2-Medium.svg#Baloo2-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo 2';
    src: url('../fonts/Baloo2/Baloo2-SemiBold.eot');
    src: url('../fonts/Baloo2/Baloo2-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baloo2/Baloo2-SemiBold.woff2') format('woff2'),
        url('../fonts/Baloo2/Baloo2-SemiBold.woff') format('woff'),
        url('../fonts/Baloo2/Baloo2-SemiBold.ttf') format('truetype'),
        url('../fonts/Baloo2/Baloo2-SemiBold.svg#Baloo2-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo 2';
    src: url('../fonts/Baloo2/Baloo2-Bold.eot');
    src: url('../fonts/Baloo2/Baloo2-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baloo2/Baloo2-Bold.woff2') format('woff2'),
        url('../fonts/Baloo2/Baloo2-Bold.woff') format('woff'),
        url('../fonts/Baloo2/Baloo2-Bold.ttf') format('truetype'),
        url('../fonts/Baloo2/Baloo2-Bold.svg#Baloo2-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo 2';
    src: url('../fonts/Baloo2/Baloo2-ExtraBold.eot');
    src: url('../fonts/Baloo2/Baloo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baloo2/Baloo2-ExtraBold.woff2') format('woff2'),
        url('../fonts/Baloo2/Baloo2-ExtraBold.woff') format('woff'),
        url('../fonts/Baloo2/Baloo2-ExtraBold.ttf') format('truetype'),
        url('../fonts/Baloo2/Baloo2-ExtraBold.svg#Baloo2-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'MerriweatherSans';
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Light.eot');
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Light.woff2') format('woff2'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Light.woff') format('woff'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Light.ttf') format('truetype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Light.svg#MerriweatherSans-Light') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'MerriweatherSans';
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Regular.eot');
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Regular.woff2') format('woff2'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Regular.woff') format('woff'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Regular.ttf') format('truetype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Regular.svg#MerriweatherSans-Regular') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MerriweatherSans';
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Bold.eot');
    src: url('../fonts/MerriweatherSans/MerriweatherSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Bold.woff2') format('woff2'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Bold.woff') format('woff'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Bold.ttf') format('truetype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-Bold.svg#MerriweatherSans-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'MerriweatherSans';
    src: url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.eot');
    src: url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.woff') format('woff'),
        url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/MerriweatherSans/MerriweatherSans-ExtraBold.svg#MerriweatherSans-ExtraBold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri/Calibri.eot');
    src: url('../fonts/Calibri/Calibri.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri/Calibri.woff2') format('woff2'),
        url('../fonts/Calibri/Calibri.woff') format('woff'),
        url('../fonts/Calibri/Calibri.ttf') format('truetype'),
        url('../fonts/Calibri/Calibri.svg#Calibri') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri/Calibri-Bold.eot');
    src: url('../fonts/Calibri/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri/Calibri-Bold.woff2') format('woff2'),
        url('../fonts/Calibri/Calibri-Bold.woff') format('woff'),
        url('../fonts/Calibri/Calibri-Bold.ttf') format('truetype'),
        url('../fonts/Calibri/Calibri-Bold.svg#Calibri-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri/Calibri-Light.eot');
    src: url('../fonts/Calibri/Calibri-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri/Calibri-Light.woff2') format('woff2'),
        url('../fonts/Calibri/Calibri-Light.woff') format('woff'),
        url('../fonts/Calibri/Calibri-Light.ttf') format('truetype'),
        url('../fonts/Calibri/Calibri-Light.svg#Calibri-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Black.eot');
    src: url('../fonts/Poppins/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Black.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Black.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Black.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Bold.eot');
    src: url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-BlackItalic.eot');
    src: url('../fonts/Poppins/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-BlackItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-BlackItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-BlackItalic.svg#Poppins-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraBold.eot');
    src: url('../fonts/Poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.eot');
    src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-BoldItalic.eot');
    src: url('../fonts/Poppins/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-BoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-BoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Italic.eot');
    src: url('../fonts/Poppins/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Italic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Italic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Italic.svg#Poppins-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Light.eot');
    src: url('../fonts/Poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Light.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Light.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Light.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraLight.eot');
    src: url('../fonts/Poppins/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraLight.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraLightItalic.eot');
    src: url('../fonts/Poppins/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-MediumItalic.eot');
    src: url('../fonts/Poppins/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-MediumItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-MediumItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Medium.eot');
    src: url('../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-LightItalic.eot');
    src: url('../fonts/Poppins/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-LightItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-LightItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-LightItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ThinItalic.eot');
    src: url('../fonts/Poppins/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ThinItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ThinItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Thin.eot');
    src: url('../fonts/Poppins/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Thin.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-SemiBoldItalic.eot');
    src: url('../fonts/Poppins/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Chivo';
    src: url('../fonts/Chivo/Chivo-Light.eot');
    src: url('../fonts/Chivo/Chivo-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Chivo/Chivo-Light.woff2') format('woff2'),
        url('../fonts/Chivo/Chivo-Light.woff') format('woff'),
        url('../fonts/Chivo/Chivo-Light.ttf') format('truetype'),
        url('../fonts/Chivo/Chivo-Light.svg#Chivo-Light') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'Chivo';
    src: url('../fonts/Chivo/Chivo-Regular.eot');
    src: url('../fonts/Chivo/Chivo-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Chivo/Chivo-Regular.woff2') format('woff2'),
        url('../fonts/Chivo/Chivo-Regular.woff') format('woff'),
        url('../fonts/Chivo/Chivo-Regular.ttf') format('truetype'),
        url('../fonts/Chivo/Chivo-Regular.svg#Chivo-Regular') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Chivo';
    src: url('../fonts/Chivo/Chivo-Bold.eot');
    src: url('../fonts/Chivo/Chivo-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Chivo/Chivo-Bold.woff2') format('woff2'),
        url('../fonts/Chivo/Chivo-Bold.woff') format('woff'),
        url('../fonts/Chivo/Chivo-Bold.ttf') format('truetype'),
        url('../fonts/Chivo/Chivo-Bold.svg#Chivo-Bold') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: 'Chivo';
    src: url('../fonts/Chivo/Chivo-Black.eot');
    src: url('../fonts/Chivo/Chivo-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Chivo/Chivo-Black.woff2') format('woff2'),
        url('../fonts/Chivo/Chivo-Black.woff') format('woff'),
        url('../fonts/Chivo/Chivo-Black.ttf') format('truetype'),
        url('../fonts/Chivo/Chivo-Black.svg#Chivo-Black') format('svg');
    font-weight: 900;
}

@font-face {
    font-family: 'NexaRustSlabBlackShadow01';
    src: url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.eot');
    src: url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.woff2') format('woff2'),
        url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.woff') format('woff'),
        url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.ttf') format('truetype'),
        url('../fonts/NexaRustSlab-BlackShadow01/NexaRustSlab-BlackShadow01.svg#NexaRustSlab-BlackShadow01') format('svg');
    font-style: normal;
}
