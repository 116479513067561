body,
html {
    font-family: "Open Sans", sans-serif;
}

/* ip = internal pages */

.ip__content-wrapper {
    background-color: #fff;
}

.ip__heading,
.ip__form-control,
.ip__link,
.ip__text,
.ip__label,
.ip__button,
.ip__page-heading,
.ip__breadcrumbs,
.ip__safe-env {
    font-family: "Open Sans", sans-serif;
}

.ip__header {
    padding: 0;
    max-height: 90px;
    box-sizing: content-box;
    padding: 15px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
}

.ip__header__logo {
    max-width: 300px;
    max-height: 60px;
}

.ip__header__logo img {
    max-height: 60px;
}

.ip__page-heading {
    color: #515151;
    margin-top: .5em;
    margin-bottom: .5em;
    font-weight: 300;
    text-transform: uppercase;
}

h2.ip__page-heading {
    font-size: 30px;
}

h3.ip__page-heading {
    font-size: 22px;
}

.ip__safe-env {
    color: #515151;
    text-transform: capitalize;
    font-size: 17px;
}

.ip__breadcrumbs {
    color: #5d5d5d;
    font-size: 12px;
    font-weight: 300;
}

.ip__breadcrumbs a {
    color: inherit;
}

.ip__heading,
.ip__label {
    color: #515151;
}

.ip__heading {
    font-weight: 600;
}

.ip__heading--has-bottom-border {
    border-bottom: 1px solid #bbb;
    padding-bottom: 0.5em;
    padding-left: .5em;
    margin-bottom: 1.25em;
}

h1.ip__heading {
    font-size: 1.5em;
}

h2.ip__heading {
    font-size: 1.25em;
}

h3.ip__heading {
    font-size: 1.1em;
}

h4.ip__heading {
    font-size: 1.2em;
}

.ip__label {
    font-size: .9em;
    font-weight: 300;
    margin-left: .25em;
    margin-bottom: .25em;
}

.ip__label.u-in-blk + .ip__label.u-in-blk {
    margin-left: 2em;
}

.ip__label--is-placeholder {
    margin: 0;
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease;
    cursor: text;
}

.ip__form-control:focus + .ip__label--is-placeholder,
.ip__label--is-floating {
    transform: none;
    top: -69%;
    left: 5px;
}

.ip__form-control {
    border-radius: 5px;
    border: 1px solid #c7c7c7;
    background-color: #fff;
    height: 34px;
    color: #222;
    font-size: 1em;
    font-weight: 300;
    padding-left: .5em;
    padding-right: .25em;
    width: 100%;
}

.ip__form-control:focus {
    outline: none;
    background-color: rgb(250, 250, 250);
    color: #515151;
    border-color: #4eaf79;
}

.ip__form-control:disabled {
    background-color: #eee;
    cursor: not-allowed;
}

.ip__form-control + .ip__form-control {
    margin-top: 8px;
}

.ip__form-control--has-label-placeholder {
    border: none;
    border-bottom: 1px solid #c7c7c7;
    border-radius: 0;
    background-image: linear-gradient(to right, #4eaf79, #4eaf79);
    background-size: 0 0;
    background-repeat: no-repeat;
    background-position: bottom left;
    transition: all .3s ease;
}

.ip__form-control--has-label-placeholder:focus {
    background-size: 100% 1px;
}

.ip__form-control--has-label-placeholder:invalid {
    box-shadow: none;
}

.ip__form-control--is-invalid:not(:focus) {
    border-color: #ff6666;
    background-image: linear-gradient(to right, #ff6666, #ff6666);
}

.form-group {
    margin-bottom: 8px;
}

.ip__button {
    border: none;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
}

.ip__button--medium {
    padding: .25em 2em;
    font-size: 1em;
    font-weight: 600;
}

.ip__button--large, .ip__button--large-with-icons {
    min-width: 210px;
    padding: .4em 3em;
    font-size: 1.25em;
    font-weight: 600;
}
.ip__button--large-with-icons {
    padding: .4em .2em;
}
.ip__button--full-width {
    width: 100%;
}

.ip__button--success,
.ip__button--success:hover,
.ip__button--success:focus,
.ip__button--success:active {
    text-decoration: none;
}

.ip__button--success:hover,
.ip__button--success:focus,
.ip__button--success:active {
    background-color: #fff;
    color: #4eaf79;
    border-color: currentColor;
}

.ip__button--success {
    color: #fff;
    background-color: #4eaf79;
    border: 2px solid transparent;
    transition: all .1s linear;
}

.ip__button--danger { }

.ip__button--centered {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.ip__button--pulled-right {
    display: block;
    margin-left: auto;
}

.ip__link {
    font-size: 1em;
    font-weight: 300;
}

.ip__link,
.ip__link:hover,
.ip__link:focus {
    text-decoration: none;
}

.ip__link--success,
.ip__link--success:hover,
.ip__link--success:focus,
.ip__link--success:active,
.ip__text--success {
    color: #4eaf79;
}

.ip__text {
    font-size: 1em;
    font-weight: 300;
}

.ip__text--small {
    font-size: 12.5px;
}

.ip__panel {
    background-color: #fff;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    padding: 1em;
}

.ip__panel--min-h-150 {
    height: 100%;
}

.ip__panel-separator--md {
    position: relative;
}

.ip__panel-separator--md::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background-color: #bbb;
    top: 60px;
    bottom: -20px;
    left: 100%;
    left: calc(100% - .5px);
}

.ip__customer-active-panel {
    padding: 1.75em 1em 2em .75em;
}

.ip__table {
    width: 100%;
}

.ip__table tbody tr th,
.ip__table tbody tr td {
}

.ip__form {
    margin-right: auto;
    margin-left: auto;
}

.ip__form--small {
    width: 90%;
    max-width: 490px;
    padding: 1em 3em;
}

.ip__form--bordered {
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    box-shadow: 0 0 3px #CCC;
}

.invalid-document-number {
    display: block;
}

.ip__error {
    border-color: #ff6666 !important;
}

.row.row-flex > [class*='col-'] {
  flex-direction: column;
}

.ip__button--order-generated-header {
    width: 20%;
}

.input-group .form-control {
    z-index: 1;
}

/* ip-new form checkout common styles */

.ip-new__content {
    margin: 50px auto;
    width: 100%;
    max-width: 360px;
}

.ip-new__mdl-content {
    margin: 50px auto;
    width: 100%;
    max-width: 570px;
    padding: 25px;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
}

.ip-new__full-content {
    margin: 50px auto;
    width: 100%;
}

.ip-new__heading {
    font-family: "Open Sans";
    color: #4c4d4f;
    font-size: 18px;
    font-weight: 300;
    margin: 0 0 10px;
}

.ip-new__paragraph {
    font-family: Gotham;
    color: #9E9E9E;
    font-size: 16px;
    font-weight: 300;
}

.ip-new__line-breaking {
    border-top: 1px solid #d1d1d1;
}

.ip-new__btn {
    font-family: "Open Sans";
    font-weight: 500;
    background-color: #058c41;
    color: #FFF;
    font-size: 17px;
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    border-color: transparent;
}

.ip-new__label {
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 300;
    margin-left: .25em;
    margin-bottom: .25em;
}

.ip-new__label.required:after {
    content: "*";
    color: #1cade4;
}

.ip-new__form-control {
    border: 1px solid #d1d1d1;
    font-family: "Open Sans";
    font-size: 13px !important;
    font-weight: 300;
    width: 100%;
    height: 42px;
    padding-left: 1em;
    margin-bottom: 6px;
    border-radius: 3px;
    background: #FFF;
}

.ip-new__link {
    font-family: "Open Sans";
    font-size: 13px;
    color: #1cade4;
}

.ip-new__input-link {
    position: absolute;
    top: 0;
    right: 15px;
}

.ip-new__content .relative {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin: 30px auto 0;
    text-align: center;
}

@media (max-width: 400px) {
    .ip-new__content {
        padding: 0 20px;
    }
}

@media (max-width: 570px) {
    .ip-new__mdl-content {
        margin: 50px 5%;
        width: 90%;
    }
}

/* end ip-new form checkout common styles */

@media (max-width: 768px) {
    .ip__header {
        flex-flow: column;
        max-height: none;
    }

    .ip__label {
        margin-left: 0;
        margin-right: 0;
    }

    a.ip__header__logo {
        float: left !important;
        display: block !important;
        position: absolute;
        left: 10px;
    }

    .ip__safe-content {
        position: absolute;
        right: 0;
    }

    h3.ip__safe-env {
        display: none;
    }

    .ip__header-content {
        height: 72px;
    }

    button.ip__button {
        margin-bottom: 50px;
    }

    .ip__button--order-generated-header {
        width: 80%;
        margin-top: 1em;
    }
}

.ml-15 {
    margin-left: 15px;
}