.u-no-margin {
    margin: 0 !important;
}

.u-no-padding {
    padding: 0 !important;
}

.u-spacing-bottom-1:not(:last-child) {
    padding-bottom: 1em;
}

.u-spacing-bottom-2:not(:last-child) {
    padding-bottom: 2em;
}

.u-spacing-bottom-top-1 {
    padding: 1em 0;
}

.u-spacing-bottom-top-2 {
    padding: 2em 0;
}

.u-spacing-bottom-top-3 {
    padding: 3em 0;
}

.u-spacing-025-2 {
    padding: .25em 2em;
}

.u-push-top-0-3 {
    margin-top: 0.3em;
}

.u-push-top-0-5 {
    margin-top: 0.5em;
}

.u-push-top-1 {
    margin-top: 1em !important;;
}

.u-push-top-1-8 {
    margin-top: 1.8em;
}

.u-push-top-2 {
    margin-top: 2em;
}

.u-push-right-1 {
    margin-right: 1em;
}

.u-push-left-1 {
    margin-left: 1em;
}

.u-push-left-2 {
    margin-left: 2em;
}

.u-push-bottom-0-5 {
    margin-bottom: 0.5em !important;
}

.u-push-bottom-1 {
    margin-bottom: 1em !important;
}

.u-push-bottom-1_5 {
    margin-bottom: 1.5em;
}

.u-push-bottom-2 {
    margin-bottom: 2em;
}

.u-push-left-2 {
    margin-left: 2em;
}

.u-push-right-0-2 {
    margin-right: 0.2em;
}

.u-no-margin-top {
    margin-top: 0px !important;
}